<template>
  <div id="cpsForm" class="bankForm_wrapper">
    <el-form label-position="top" :model="form" :rules="TransferFormRules" ref="TransferForm">
      <el-row v-if="isShowSelectBankAccount">
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <SelectForm
            ref="selectedCardIDInput"
            v-model="TransferForm.selectedCardID"
            :label="$t('withdraw.default.selectBA')"
            name="selectedCardID"
            class="l-select"
            testId="selectedCardID"
          >
            <el-option
              v-for="item in availableCards"
              :key="item.id"
              :value="item.id"
              :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
              :data-testid="`select_${item.id}`"
            ></el-option>
          </SelectForm>
        </el-col>
      </el-row>

      <el-row :gutter="40" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
        <el-col :xs="24" :sm="12" v-for="(type, index) in form.attachVariables" :key="index">
          <!-- Input Field -->
          <DynamicRestrictInput
            :label="getFieldLabel(type.field_name)"
            :name="'attachVariables.' + index + '.value'"
            :testId="getTestId(type.key, type.field_type)"
            :restrictionType="type.restriction_type"
            v-model="form.attachVariables[index].value"
            :maxlength="type.field_length"
            :disabled="isdisabled"
            v-if="isInputField(type.field_type)"
          ></DynamicRestrictInput>
          <!-- Dropdown List -->
          <el-form-item
            v-if="type.field_type == 'dropdown_list' && type.type === 'bank'"
            :label="$t('common.field.bankName')"
            :prop="'attachVariables.' + index + '.value'"
            :rules="selectRules"
          >
            <el-select
              v-model="form.attachVariables[index].value"
              :placeholder="$t('common.field.select')"
              :data-testid="getTestId(type.key, type.field_type)"
              :disabled="isdisabled"
            >
              <el-option
                :label="value.bank_name"
                :value="value.bank_code"
                v-for="(value, name) in type.field_content"
                :key="name"
                :data-testid="`${getTestId(type.key, type.field_type)}_${value}`"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="type.field_type == 'dropdown_list' && type.type !== 'bank'"
            :label="getFieldLabel(type.field_name)"
            :prop="'attachVariables.' + index + '.value'"
            :rules="selectRules"
          >
            <el-select
              v-model="form.attachVariables[index].value"
              :placeholder="$t('common.field.select')"
              :data-testid="getTestId(type.key, type.field_type)"
              :disabled="isdisabled"
              @change="clearSubFieldValue(index, type.subBranchLength)"
            >
              <el-option
                :label="getFieldLabel(value.desc)"
                :value="value.value"
                v-for="(value, name) in type.field_content"
                :key="name"
                :data-testid="`${getTestId(type.key, type.field_type)}_${value}`"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- Dropdown Sub List -->
          <el-form-item
            v-if="
              type.field_type == 'dropdown_list_sub' &&
              form.attachVariables[index - type.siblingIndex].value === type.dependCode
            "
            :label="getFieldLabel(type.field_name)"
            :prop="'attachVariables.' + index + '.value'"
            :rules="selectRules"
          >
            <el-select
              v-model="form.attachVariables[index].value"
              :data-testid="getTestId(type.key, type.field_type)"
              :placeholder="$t('common.field.select')"
              :disabled="isdisabled"
            >
              <el-option
                :label="value.desc"
                :value="value.value"
                v-for="(value, i) in type.field_content"
                :key="i"
                :data-testid="`${getTestId(type.key, type.field_type)}_${value}`"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- Input Field Sub -->
          <el-form-item
            v-if="
              (type.field_type == 'input_field_sub' || type.field_type == 'email_sub') &&
              form.attachVariables[index - type.siblingIndex].value === type.dependCode
            "
            :label="getFieldLabel(type.field_name)"
            :key="index"
            :prop="'attachVariables.' + index + '.value'"
            :rules="inputRules"
            class="showPlaceholder"
          >
            <el-input
              :data-testid="getTestId(type.key, type.field_type)"
              v-model="form.attachVariables[index].value"
              :disabled="isdisabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <!-- Important Notes -->
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <ImportantNotesInput v-model="form.importantNotes" data-testid="importantNotes"></ImportantNotesInput>
          </el-form-item>
        </el-col>
      </el-row>

      <p v-if="isShowSelectBankAccount && withdrawalType !== 2" class="mb-2 text_red">
        {{ $t('withdraw.LBTchannelsNote') }}
      </p>

      <div
        class="checkbox_wrapper"
        v-if="!form.userPaymentInfoId && isShowSelectBankAccount && TransferForm.selectedCardID == -1"
      >
        <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </el-form>
    <FeeTip
      :withdrawalType="withdrawalType"
      :currency="currency"
      :withdrawalAmount="amount"
      :styleType="2"
      :mt4Account="accountNumber"
    />
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { validateEmail } from '@/util/validation';
import { apiPaymentSelection } from '@/resource';
import SelectForm from '@/components/form/Select';
import DynamicRestrictInput from '@/components/form/DynamicRestrictInput';
import fieldLabelList from '@/constants/withdrawFieldLabel';
import { selectedCardInfoMapping, attachVarCardInfoMapping } from '@/constants/payment/withdrawSetting.json';
import FeeTip from '@/components/withdraw/FeeTip';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'CpsWithdraw',
  components: { SelectForm, DynamicRestrictInput, FeeTip, ImportantNotesInput },
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: {},
    chooseWithdrawalType: Object,
    currency: String,
    withdrawalPayment: Object
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      form: {
        bankName: '',
        bankProvince: '',
        bankCity: '',
        bankAccountName: '',
        bankBranchName: '',
        accountNumber: '',
        importantNotes: '',
        userPaymentInfoId: null,
        attachVariables: [],
        specialVariables: [],
        finalAttachVariables: {}
      },
      TransferFormRules: {
        selectedCardID: [
          {
            validator: (rule, value, callback) =>
              this.$refs.selectedCardIDInput
                ? this.TransferForm.selectedCardID !== null && this.TransferForm.selectedCardID !== undefined
                  ? callback()
                  : callback(new Error(this.$t('common.formValidation.selectBAReq')))
                : callback(),
            trigger: 'change'
          }
        ]
      },
      selectedCardInfoID: '',
      showSelectBankAccountList: [
        2, 5, 6, 8, 9, 24, 35, 39, 40, 42, 47, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 60, 62, 63, 64, 67, 68, 71, 72,
        73, 74, 76, 77, 88, 89, 90, 100, 102, 108, 110, 113
      ],
      selectRules: {
        required: true,
        message: this.$t('common.formValidation.common'),
        trigger: 'change'
      },
      emailRules: [
        {
          required: true,
          message: this.$t('common.formValidation.dynamicReq', {
            dynamic: this.$t('register.personalDetails.page1.email')
          }),
          trigger: 'blur'
        },
        {
          validator: validateEmail,
          trigger: 'blur'
        }
      ],
      inputRules: {
        required: true,
        message: this.$t('common.formValidation.common'),
        trigger: 'blur'
      }
    };
  },
  methods: {
    getTestId(val, type) {
      switch (val) {
        case 'holderAddress':
          return 'bankAddress';
        case 'beneficiaryName':
          return 'beneficiaryName';
        case 'bank_branch':
          if (type === 'dropdown_list_sub') {
            return 'selectedBankBranch';
          }
          return 'bankBranch';
        case 'card_name':
          return 'accountName';
        case 'card_number':
          return 'accountNumber';
        case 'city':
          return 'bankCity';
        case 'province':
          return 'bankProvince';
        case 'bank_code':
          return 'bankName';
        case 'ifsc_code':
          return 'ifscCode';
        case 'attach_account_type':
          return 'accountType';
        default:
          return val;
      }
    },
    getFieldLabel(val) {
      const i18nKey = this.getI18nKey(val);
      if (this.$te(i18nKey) || this.$te(i18nKey, 'en_US')) {
        if (i18nKey === 'common.field.paymentAccName' || i18nKey === 'common.field.paymentAccNum')
          return this.$t(i18nKey, { name: val.split(' ')[0] });
        return this.$t(i18nKey);
      }
      return val;
    },
    getI18nKey(val) {
      let label = fieldLabelList[val];
      return label ? label : val;
    },
    getPaymentInfoList(val) {
      apiPaymentSelection({ mtAcc: this.accountNumber, paymentType: val })
        .then(resp => {
          this.availableCards = Array.isArray(resp.data) ? resp.data : [];
        })
        .finally(resp => {
          this.addCustomOption();
          this.TransferForm.selectedCardID = this.availableCards[0].id;
        });
    },
    reconfigureAttachVariables() {
      this.form.attachVariables = JSON.parse(this.withdrawalPayment.merchant_variable)?.customer_input
        ? JSON.parse(this.withdrawalPayment.merchant_variable)?.customer_input
        : [];

      // filter by attach variables key in object
      let attachVariables = this.withdrawalPayment.attach_variable
        ? Object.values(JSON.parse(this.withdrawalPayment.attach_variable))
        : '';

      this.form.specialVariables = [];
      attachVariables.forEach(f => {
        if (f.hasOwnProperty('field_type')) {
          this.form.specialVariables.push(f);
          if (f.field_type === 'dropdown_list') this.createSubField(f);
        }
      });
      if (this.form.specialVariables.length > 0) {
        this.form.attachVariables = [...this.form.specialVariables, ...this.form.attachVariables];
      }
    },
    createSubField(field) {
      field.field_content.forEach((fc, i) => {
        if (fc.field_type) {
          field.subBranchLength = field.subBranchLength ? field.subBranchLength + 1 : 1;
          this.form.specialVariables.push({
            field_type: fc.field_type + '_sub',
            field_name: fc.field_name,
            type: fc.type,
            key: fc.key,
            dependKey: field.key,
            dependCode: fc.bank_code,
            siblingIndex: i + 1,
            field_content: fc.field_content
          });
        }
      });
    },
    clearSubFieldValue(index, subBranchLength) {
      if (!subBranchLength) return;
      for (let i = index + 1; i <= subBranchLength; i++) {
        delete this.form.attachVariables[i].value;
      }
    },
    getFormData() {
      // map attach variables to accept only key & value format
      this.form.finalAttachVariables = {};
      this.form.attachVariables.map(a => {
        if (a.key != 'mail' && a.value) {
          this.form.finalAttachVariables[a.key] = a.value;
        }
      });
      // get old key
      let cpsKeyData = {};
      const withdrawConfig = Object.assign({}, attachVarCardInfoMapping, this.attachVarCardInfo);
      Object.entries(withdrawConfig).forEach(([key, value]) => {
        let isCheckValue = ['branch_code', 'bank_branch'].includes(key);
        if (!cpsKeyData[value]) cpsKeyData[value] = this.getParamsValue(key, isCheckValue);
      });
      // get new key
      let additionalCpsKeyData = {};
      Object.entries(this.additionalUserInfoConfig).forEach(([key, value]) => {
        if (!additionalCpsKeyData[value]) additionalCpsKeyData[value] = this.getParamsValue(key);
      });
      const rawData = {
        ...cpsKeyData,
        ...additionalCpsKeyData
      };

      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.TransferForm.selectedCardID == -1 ? this.isRememberInfo : '',
        userPaymentInfoId: this.selectedCardInfoID ? this.selectedCardInfoID : '',
        orderCurrency: this.withdrawalPayment.actual_currency_number,
        paymentMethodCode: this.withdrawalPayment.payment_method,
        attachVariables: JSON.stringify(this.form.finalAttachVariables),
        mandatory: JSON.parse(this.withdrawalPayment.attach_variable).mandatory.toString(),
        ...cpsKeyData,
        raw: JSON.stringify(rawData)
      };
    },
    isInputField(fieldType) {
      const inputType = ['input_field', 'email'];
      return inputType.includes(fieldType);
    },
    getParamsValue(key, isCheckValue = false) {
      if (isCheckValue) return this.form.attachVariables?.find(type => type.key === key && type.value)?.value || '';
      else return this.form.attachVariables?.find(type => type.key === key)?.value || '';
    }
  },
  computed: {
    isShowSelectBankAccount() {
      return this.showSelectBankAccountList.includes(this.withdrawalType);
    },
    selectedCardInfo() {
      return this.withdrawalPayment.user_info_config
        ? JSON.parse(this.withdrawalPayment.user_info_config).selectedCardInfo || {}
        : {};
    },
    attachVarCardInfo() {
      return this.withdrawalPayment.user_info_config
        ? JSON.parse(this.withdrawalPayment.user_info_config).attachVarCardInfo || {}
        : {};
    },
    additionalUserInfoConfig() {
      return this.withdrawalPayment.user_info_config
        ? JSON.parse(this.withdrawalPayment.user_info_config).additionalField || {}
        : {};
    }
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      const isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.selectedCardInfoID = isCurrentCard ? selectedCardInfo.id : '';

      if (isCurrentCard) {
        this.$nextTick(() => {
          this.reconfigureAttachVariables();

          const mappings = [
            { config: this.selectedCardInfo, source: selectedCardInfo },
            { config: this.additionalUserInfoConfig, source: JSON.parse(selectedCardInfo.raw) },
            { config: selectedCardInfoMapping, source: selectedCardInfo }
          ];

          this.form.attachVariables.forEach(a => {
            const mapping = mappings.find(({ config }) => config[a.key]);
            if (mapping) {
              a.value = mapping.source[mapping.config[a.key]];
            }
          });
        });
      } else {
        this.$nextTick(() => {
          this.reconfigureAttachVariables();
          let accountNameIndex = this.form.attachVariables.findIndex(obj => obj.key == 'card_name');
          if (accountNameIndex >= 0 && ![62, 102].includes(this.withdrawalType)) {
            this.$set(this.form.attachVariables[accountNameIndex], 'value', this.$store.state.common.userName);
          }
        });
      }

      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.beneficiaryName'(value) {
      this.form.beneficiaryName = this.latinNumberValidator(value);
    },
    withdrawalType: {
      immediate: true,
      handler(val) {
        this.getPaymentInfoList(val);
        // if (this.showSelectBankAccountList.includes(val)) {
        //   this.TransferForm.selectedCardID = null;
        // } else {
        //   this.TransferForm.selectedCardID = 0;
        // }
        this.reconfigureAttachVariables();
      }
    }
  }
};
</script>
